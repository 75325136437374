import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"
import { StaticQuery, graphql } from "gatsby"
import { connect } from "react-redux"
/* import { get_types_produitAnnexe } from "../services/selectNiveaux" */

import SEO from "./../components/seo"

import Layout from "../components/layout"
import slugify from "slugify"

class PassePartoutPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      typesPassePartout: this.get_typesPassePartout(this.props.mesPassePartout.allMysqlPassePartout.edges),
    }
  }

  get_typesPassePartout = products => {
    let typesPassePartout = []
    products
      .filter(u => u.node.pat_id !== 16)
      .map(({ node }) => {
        if (!typesPassePartout.includes(node.pat_id)) {
          typesPassePartout.push(node.pat_id)
        }
      })
    return typesPassePartout.sort(a => a == 18)
  }

  render() {
    return (
      <>
        <Layout banner={false} location={this.props.location}>
          <SEO title="Passe-partout" description="Passe partout à vos mesures pour encadrer vos sujets. Blanc, noir ou couleur. Epaisseur 1.4mm ou 3.3mm" />
            <div className="columns RemoveFullPaddingMobile" style={{ marginTop: "4.6rem", marginBottom: "0.5rem" }}>
              <div className="column is-9 family pt-6">
                {this.state.typesPassePartout.map((id_type, index1) => (
                  <section key={index1} id={`menu-${id_type}`}>
                    <h2>
                      {this.props.mesPassePartout.allMysqlPassePartout.edges.filter(u => u.node.pat_id === id_type)[0].node.pat_libelle.replace("80x120cm", "")}
                    </h2>
                    <div className="family-grid family-gridPP">
                      {this.props.mesPassePartout.allMysqlPassePartout.edges
                        .sort((a, b) => a.node.copp_order - b.node.copp_order)
                        .map(({ node }, index) =>
                          node.pat_id === id_type ? (
                            <div key={index}>
                              <Link to={`/passe-partout/${slugify(node.pa_libelle.replace("Passe-partout", ""))}-${node.pa_id}`}>
                                <div
                                  title={node.pa_passe_couleur}
                                  style={{ backgroundColor: node.pa_passe_hexa }}
                                  className={`couleur couleurPassePartout`}
                                  onKeyPress={() => {}}
                                  tabIndex={0}
                                  role="button"
                                >
                                  <p className={"colorSelectedTitle"} style={{ height: "auto", textAlign: "left" }}>
                                    {node.pa_passe_couleur}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          ) : (
                            ""
                          )
                        )}
                    </div>
                  </section>
                ))}
              </div>
              <div className="column is-3 is-fullheight has-background-grey-lighter">
                <ul className="menuCategory" style={{ top: "6rem" }}>
                  {this.state.typesPassePartout.map((id_type, index1) => (
                    <li key={index1}>
                          <a
                            href={`cadres/passe-partout/#menu-${id_type}`}
                            className={`navbar-item`}
                            onClick={evt => {
                              evt.preventDefault()
                              window.scrollTo({
                                top: window.document.querySelector(`#menu-${id_type}`).offsetTop,
                                left: 0,
                                behavior: "smooth",
                              })
                            }}
                          >
                            {this.props.mesPassePartout.allMysqlPassePartout.edges
                              .filter(u => u.node.pat_id === id_type)[0]
                              .node.pat_libelle.replace("80x120cm", "")}
                          </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
        </Layout>
      </>
    )
  }
}

PassePartoutPage.propTypes = {
  identifiants: PropTypes.object,
  mesPassePartout: PropTypes.object,
  location: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    identifiants: state.identifiants,
  }
}

const ConnectFunction = connect(mapStateToProps)(PassePartoutPage)

const StaticPassePartoutPage = props => (
  <StaticQuery
    query={graphql`
      query passePartout {
        allMysqlPassePartout {
          edges {
            node {
              pa_id
              pat_libelle
              pat_id
              pa_libelle
              pa_description
              pa_reference
              pa_passe_couleur
              pa_passe_hexa
              copp_order
            }
          }
        }
      }
    `}
    render={mesPassePartout => <ConnectFunction mesPassePartout={mesPassePartout} {...props} />}
  />
)

export default StaticPassePartoutPage
